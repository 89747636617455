.cardContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    border-radius: 25px;
    padding: 50px;
    transform-origin: top;
  }
  
  /* .card h2 {
    text-align: center;
    margin: 0px;
    font-size: 28px;
  } */
  
  .body {
    display: flex;
    height: 100%;
    margin-top: 50px;
    gap: 50px;
  }
  
  /* .description {
    width: 40%;
    position: relative;
    top: 10%;
  }
  
  .description p {
    font-size: 16px;
  }
  
  .description p::first-letter {
    font-size: 28px;
    font-family: 'Title';
  }
  
  .description span {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .description span a {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  } */
  
  /* .imageContainer {
    position: relative;
    width: 60%;
    height: 100%;
    border-radius: 25px;
    overflow: hidden;
  } */
  
  .inner {
    width: 100%;
    height: 100%;
  }
  
  .imageContainer img {
    object-fit: cover;
  }
  