@import url("https://fonts.cdnfonts.com/css/messina");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SharpGrotesk";
  src: url("../src/assets/fonts/SharpGroteskBook25.ttf") format("truetype");
}

* {
  font-family: "Manrope", sans-serif;
}

.font-rubik {
  font-family: "Manrope", sans-serif;
}

.parallax-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  /* or scroll if you want scrollbars */
  height: 100vh;
  /* Adjust height as needed */
}

/* custom-styles.css */

/* Hero Section */
.custom-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-content {
  width: 100%;
  text-align: center;
}

.custom-text {
  font-size: 0.75rem;
  /* text-xs */
  margin-right: 0.5rem;
  /* mr-2 */
}

.custom-img {
  width: auto;
  height: auto;
}

.custom-heading {
  font-size: 1.125rem;
  /* text-lg */
  font-weight: 800;
  /* font-extrabold */
  margin-bottom: 0.75rem;
  /* mb-3 */
}

.custom-paragraph {
  font-size: 0.875rem;
  /* text-sm */
  margin-bottom: 0.75rem;
  /* mb-3 */
  font-family: var(--font-para);
  /* font-para */
}

.custom-card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  /* mt-8 */
}

/* Phone Model */
.model-img {
  width: 80%;
  /* w-[80%] */
  height: 24rem;
}

@media (min-width: 800px) {
  .custom-flex {
    flex-direction: row;
    margin-top: 0;
  }

  .custom-content {
    width: 50%;
    /* md:w-1/2 */
    margin-left: 3rem;
    /* md:ml-12 which is 3rem */
    text-align: left;
    /* md:text-left */
  }

  .custom-text {
    font-size: 0.875rem;
    /* text-sm */
  }

  .custom-img {
    width: 5rem;
    /* 20 * 0.25rem = 5rem */
    height: 5rem;
    /* 20 * 0.25rem = 5rem */
  }

  .custom-heading {
    font-size: 2.25rem;
    /* text-4xl */
  }

  .custom-paragraph {
    font-size: 1rem;
    /* text-base */
  }

  .custom-card-container {
    margin-top: 0;
    /* md:mt-0 */
    width: 50%;
    /* md:w-1/2 */
  }

  /* Phone Model */
  .model-img {
    height: 100%;
    /* md:h-full */
  }
}